import React, { useEffect, useRef } from "react";
import styled from "styled-components";

const Map = styled.div`
  width: 100%;
  height: 500px;

  @media (max-width: 768px) {
    height: 300px;
  }
`;

const NaverMap = ({ width, height, lat, lng, zoom }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    const initializeMap = () => {
      const { naver } = window;
      if (!mapRef.current || !naver) return;

      const location = new naver.maps.LatLng(lat, lng);
      const mapOptions = {
        center: location,
        zoom: zoom,
      };
      const map = new naver.maps.Map(mapRef.current, mapOptions);

      // 마커 생성
      new naver.maps.Marker({
        position: location,
        map: map,
      });
    };

    const script = document.createElement("script");
    script.src = `https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=7p5ljup397`;
    script.async = true;
    script.onload = initializeMap;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [lat, lng, zoom]); // 의존성 배열에 props 추가

  return <Map ref={mapRef} />;
};

export default NaverMap;

// import React, { useEffect, useRef } from 'react';

// const NaverMap = ({ width, height, lat, lng, zoom }) => {
//     const mapRef = useRef(null);

//     useEffect(() => {
//         const initializeMap = () => {
//             const { naver } = window;
//             if (!mapRef.current || !naver) return;

//             const location = new naver.maps.LatLng(lat, lng);
//             const mapOptions = {
//                 center: location,
//                 zoom: 14,
//             };
//             new naver.maps.Map(mapRef.current, mapOptions);
//         };

//         const script = document.createElement('script');
//         script.src = `https://openapi.map.naver.com/openapi/v3/maps.js?ncpClientId=7p5ljup397`;
//         script.async = true;
//         script.onload = initializeMap;
//         document.head.appendChild(script);

//         return () => {
//             document.head.removeChild(script);
//         };
//     }, []);

//     return <div ref={mapRef} style={{ width: '100%', height: '400px' }} />;
// };

// export default NaverMap;
