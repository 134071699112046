import React from "react";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
import "../styles/FacilitiesPage.css";
import SubHero from "../components/SubHero.js";
import CustomImageSlider from "../components/CustomImageSlider.js";
import { ImageMap } from "../assets/constants/facility-image-map.js";
import NaverStaticMap from "../components/NaverStaticMap.js";

function Facilities() {
  const facilitySliderContent = [
    { image: "facility_1" },
    { image: "facility_2" },
    { image: "facility_3" },
    { image: "facility_4" },
    { image: "facility_5" },
    { image: "facility_6" },
  ];

  return (
    <div className="facilities-wrapper">
      <SubHero
        title={"Facilities"}
        text={"최고의 시설에서 펼쳐지는 최고의 퍼포먼스"}
      />
      <div className="facilities-backgroud">
        <section className="facilities-section">
          <Container>
            <div className="facilities-wrap">
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.5,
                  y: { duration: 0.5 },
                }}
              >
                <h4 className="facilities-topic">Facilities</h4>
                <h5 className="facilities-tilte">
                  스포모티브 시설 안내
                  <p className="facilities-title-sub">24시간 영업 연중무휴</p>
                </h5>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                  ease: "easeInOut",
                  duration: 1,
                  y: { duration: 1.5 },
                  delay: 0.5,
                }}
              >
                <p className="facilities-content">
                  스포모티브는 프리미엄 코트와 전문 스포츠 바닥재를 갖춘 최상의
                  운동 환경을 제공하며, 뛰어난 경기력과 안전성을 보장해 운동의
                  즐거움을 극대화할 수 있는 공간입니다. 카페, 요가 스튜디오,
                  샤워시설 등 다양한 편의시설을 통해 쾌적하고 편안한 스포츠
                  환경을 제공합니다. 스포모티브는 단순히 운동을 위한 공간이
                  아닌, 활기차고 건강한 라이프스타일을 추구하는 모든 분들에게
                  최적의 환경을 제공합니다.
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                  ease: "easeInOut",
                  duration: 1,
                  y: { duration: 1.5 },
                  delay: 0.5,
                }}
              >
                <CustomImageSlider content={facilitySliderContent} />
              </motion.div>
            </div>
          </Container>
        </section>

        <section className="facilities-pros-section">
          <Container className="facilities-pros-container">
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeInOut",
                duration: 1,
                y: { duration: 0.5 },
              }}
            >
              <h5 className="facilities-pros-title-deco">한국 #1</h5>
              <h4 className="facilities-pros-title">
                프리미엄 피클볼 인도어 코트
              </h4>
            </motion.div>
            <div className="facilities-pros-wrap">
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                  ease: "easeInOut",
                  duration: 1,
                  y: { duration: 1.0 },
                  delay: 0.5,
                }}
              >
                <div className="facilities-pros-item">
                  <img
                    loading="lazy"
                    src={ImageMap["facility_pros_1"]}
                    alt="facility_pros_1"
                  />
                  <div className="facilities-pros-item-text">
                    <div className="circle">1</div>
                    <p className="facilities-pros-item-text-title">
                      프로페셔널
                      <br />
                      피클볼 전용 코트 2면
                    </p>
                  </div>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                  ease: "easeInOut",
                  duration: 1,
                  y: { duration: 1.0 },
                  delay: 0.5,
                }}
              >
                <div className="facilities-pros-item">
                  <img
                    loading="lazy"
                    src={ImageMap["facility_pros_2"]}
                    alt="facility_pros_2"
                  />
                  <div className="facilities-pros-item-text">
                    <div className="circle">2</div>
                    <p>
                      최고급 피클볼
                      <br />
                      전용 플로어링
                    </p>
                  </div>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                  ease: "easeInOut",
                  duration: 1,
                  y: { duration: 1.0 },
                  delay: 0.5,
                }}
              >
                <div className="facilities-pros-item">
                  <img
                    loading="lazy"
                    src={ImageMap["facility_pros_3"]}
                    alt="facility_pros_3"
                  />
                  <div className="facilities-pros-item-text">
                    <div className="circle">3</div>
                    <p>국제 규격 시설</p>
                  </div>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                  ease: "easeInOut",
                  duration: 1,
                  y: { duration: 1.0 },
                  delay: 0.5,
                }}
              >
                <div className="facilities-pros-item">
                  <img
                    loading="lazy"
                    src={ImageMap["facility_pros_4"]}
                    alt="facility_pros_4"
                  />
                  <div className="facilities-pros-item-text">
                    <div className="circle">4</div>
                    <p>
                      프로 선수
                      <br />
                      트레이닝 시설
                    </p>
                  </div>
                </div>
              </motion.div>
            </div>
          </Container>
        </section>
      </div>
      <section className="amenities-section">
        <Container className="amenities-container">
          <div className="amenities-warp">
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeInOut",
                duration: 0.5,
                y: { duration: 0.5 },
              }}
            >
              <h4 className="facilities-topic">Amenities</h4>
              <h5 className="facilities-tilte">편의시설</h5>
            </motion.div>
            <div className="amenities-list-wrap">
              <div className="amenities-list">
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{
                    ease: "easeInOut",
                    duration: 1,
                    y: { duration: 1.0 },
                    delay: 0.5,
                  }}
                >
                  <div className="amenities-item-wrap">
                    <div className="amenities-item">
                      <div className="amenities-icon">
                        <img src="/icon/wifi.svg" loading="lazy" alt="WIFI" />
                      </div>
                      <div className="amenities-text">와이파이</div>
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{
                    ease: "easeInOut",
                    duration: 1,
                    y: { duration: 1.0 },
                    delay: 0.5,
                  }}
                >
                  <div className="amenities-item-wrap">
                    <div className="amenities-item">
                      <div className="amenities-icon">
                        <img
                          src="/icon/shower.svg"
                          loading="lazy"
                          alt="shower"
                        />
                      </div>
                      <div className="amenities-text">샤워시설</div>
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{
                    ease: "easeInOut",
                    duration: 1,
                    y: { duration: 1.0 },
                    delay: 0.5,
                  }}
                >
                  <div className="amenities-item-wrap">
                    <div className="amenities-item">
                      <div className="amenities-icon">
                        <img
                          src="/icon/locker.svg"
                          loading="lazy"
                          alt="locker"
                        />
                      </div>
                      <div className="amenities-text">락커룸</div>
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{
                    ease: "easeInOut",
                    duration: 1,
                    y: { duration: 1.0 },
                    delay: 0.5,
                  }}
                >
                  <div className="amenities-item-wrap">
                    <div className="amenities-item">
                      <div className="amenities-icon">
                        <img src="/icon/cafe.svg" loading="lazy" alt="cafe" />
                      </div>
                      <div className="amenities-text">카페</div>
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{
                    ease: "easeInOut",
                    duration: 1,
                    y: { duration: 1.0 },
                    delay: 0.5,
                  }}
                >
                  <div className="amenities-item-wrap">
                    <div className="amenities-item">
                      <div className="amenities-icon">
                        <img
                          src="/icon/pro-shop.svg"
                          loading="lazy"
                          alt="pro shop"
                        />
                      </div>
                      <div className="amenities-text">프로샵</div>
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{
                    ease: "easeInOut",
                    duration: 1,
                    y: { duration: 1.0 },
                    delay: 0.5,
                  }}
                >
                  <div className="amenities-item-wrap">
                    <div className="amenities-item">
                      <div className="amenities-icon">
                        <img
                          src="/icon/parking.svg"
                          loading="lazy"
                          alt="parking"
                        />
                      </div>
                      <div className="amenities-text">주차가능</div>
                    </div>
                  </div>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{
                    ease: "easeInOut",
                    duration: 1,
                    y: { duration: 1.0 },
                    delay: 0.5,
                  }}
                >
                  <div className="amenities-item-wrap">
                    <div className="amenities-item">
                      <div className="amenities-icon">
                        <img src="/icon/rest.svg" loading="lazy" alt="rest" />
                      </div>
                      <div className="amenities-text">휴식공간</div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section className="map-section">
        <Container className="map-container">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              ease: "easeInOut",
              duration: 0.5,
              y: { duration: 0.5 },
            }}
          >
            <h4 className="facilities-topic">Locations</h4>
            <h5 className="facilities-tilte">찾아 오시는 길</h5>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              ease: "easeInOut",
              duration: 1,
              y: { duration: 1.0 },
              delay: 0.5,
            }}
          >
            <div className="map-wrap">
              <div className="map-div">
                <NaverStaticMap
                  width={600}
                  height={400}
                  lat={37.5029725833055}
                  lng={127.010131309177}
                  zoom={14}
                />
              </div>
              <div className="name-card">
                <p className="name-card-title">
                  서울시 고무래로 18 - 4 지하 2층
                </p>
                <p className="name-card-content">전화 : 0507-1325-4349 </p>
                <p className="name-card-content">
                  이메일 : jh.song@spomotive.com
                </p>
                <p className="name-card-content">팩스 02-6455-4349</p>
              </div>
            </div>
          </motion.div>
        </Container>
      </section>
    </div>
  );
}

export default Facilities;
