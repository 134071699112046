// SponsorBanner.js
import React from "react";
import "../styles/Sponsor.css";
import { ImageMap } from "../assets/constants/logo-image-map.js";

const MainSponsor = () => {
  return (
    <div className="main-sponsor-wrap">
      <h2
        className="fw-bold pb-3 "
        style={{ color: "var(--primary-color)", fontSize: "var(--h4-size)" }}
      >
        SPONSORED BY
      </h2>
      <img
        loading="lazy"
        className="pb-3"
        src={ImageMap["SPARKLE_MAIN"]}
        alt="Sparkle"
      />
      <img
        loading="lazy"
        className="pb-3"
        src={ImageMap["joola_purple"]}
        alt="Joola"
      />
      
      {/* <p class="fw-bold" style={{ color: "#000", fontSize: "var(--h4-size)" }}>
        스포모티브는 <span className="point-color">JOOLA</span> <span className="sparkle">스파클</span>과 함께 합니다.
      </p> */}
    </div>
  );
};

export default MainSponsor;
