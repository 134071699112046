import React, { useState } from "react";
import { Container } from "react-bootstrap";
import SubHero from "../components/SubHero.js";
import "../styles/NewsPage.css";

const AccordionItem = ({ title, content, category, date }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="accordion-item">
      <div
        className={`accordion-header ${isOpen ? "active" : ""}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="accordion-title-wrap">
          <div className="accordion-category-wrap">
            <p className="accordion-category">[{category}]</p>
            <p className="accordion-date">{date}</p>
          </div>
          <h4 className="accordion-item-title">{title}</h4>
          <div className="red-ellipse">
            <img
              src="/icon/red-ellipse.svg"
              loading="lazy"
              alt="Red Ellipse"
              width="11"
              height="11"
            />
          </div>
        </div>
        <span className="arrow">{isOpen ? "▲" : "▼"}</span>
      </div>
      <div className={`accordion-content ${isOpen ? "open" : ""}`}>
        <div className="accordion-item-hr"></div>
        <div
          className="accordion-content-text"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    </div>
  );
};

function News() {
  const items = [
    {
      title: "2024년 11월 중순 임시 오픈",
      content: `스포모티브는 현재 세부 공사가 진행 중이지만, 프리미엄 피클볼 코트 2면은
                        완벽히 준비되어 운영되고 있습니다.
                        편안한 환경에서 최고의 시설을 경험하며 마음껏 플레이해 보세요.
                        <span style="color:#FFDB60">현재 제공되는 가격과 프로그램은 모두 임시로 설정</span>되어 있으며, 고객님께 더 나은 서비스를 제공하기 위해
                        다양한 옵션들을 신중히 검토하고 있습니다. 레슨 프로그램, 오픈 코트 플레이, 대관 서비스 등의
                        세부 사항은 최종 결정 후 적용 및 변경될 예정입니다.`,
      category: "업데이트",
      date: "2024.12.13",
    },
    {
      title: "12월부터 피클볼 전문 레슨 진행",
      content: `스포모티브에서는 12월부터 피클볼 전문 레슨 프로그램을 시작합니다.
                        <span style="color:#FFDB60">주니어와 주부반, 학생반, 중급자 클래스를 운영</span>하며,
                        정기적인 무료 피클볼 스킬 클리닉도 진행해
                        누구나 쉽게 참여하고 실력을 키울 수 있는 기회를 제공합니다.
                        스포모티브에서 다양한 레벨에 맞춘 맞춤형 레슨으로 피클볼의 즐거움을 느껴보세요!`,
      category: "일반",
      date: "2024.12.13",
    },
    {
      title: "2024년 2월 정식 오픈",
      content: `2024년 2월, 스포모티브가 정식 오픈하여 더욱 완벽하게 업그레이드된 시설과 다양한 프로그램으로
                        여러분을 찾아갑니다. <span style="color:#FFDB60">정식 오픈 이후에는 샤워실, 요가 공간, 러닝 시설</span> 등 새로운 프리미엄 서비스가 추가되어
                        더욱 쾌적하고 다채로운 스포츠 환경을 제공합니다. 현재도 프리미엄 피클볼 코트 2면이 완벽히 운영 중이니
                        언제든 방문하셔서 피클볼의 즐거움을 경험해보세요. 스포모티브와 함께 건강하고 즐거운 시간을 만들어 보세요!`,
      category: "업데이트",
      date: "2024.12.13",
    },
  ];
  return (
    <div className="news-wrapper">
      <SubHero title={"NEWS"} text={"공지사항"} />
      <section>
        <Container className="text-center">
          <h3 className="news-title">"공지사항"</h3>
          <div className="custom-hr" />
          <div className="accordion">
            {items.map((item, index) => (
              <AccordionItem
                title={item.title}
                content={item.content}
                category={item.category}
                date={item.date}
              />
            ))}
          </div>
        </Container>
      </section>
    </div>
  );
}

export default News;
