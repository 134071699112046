import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { scroller, Element } from "react-scroll";
import Facilities from "../components/Facilities.js";
import "../styles/Main.css";
import "../styles/Facilities.css";
import "../styles/Program.css";
import "../styles/Notice.css";
import Program from "../components/Program.js";
import SubSponsor from "../components/SubSponsor.js";
import MainSponsor from "../components/MainSponsor.js";
import Notice from "../components/Notice.js";
import Announcement from "../components/announcemrnt.js";
import { motion } from "framer-motion";
import CustomSlider from "../components/CustomSlider.js";
import MainHero from "../components/MainHreo.js";

function Main() {
  const facilitySliderContent = [
    { image: "main_facilities_1" },
    { image: "main_facilities_2" },
    { image: "main_facilities_3" },
    { image: "main_facilities_4" },
  ];
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0 && !hasScrolled) {
        setHasScrolled(true);
        scrollToElement();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasScrolled]);

  const scrollToElement = () => {
    scroller.scrollTo("targetElementName", {
      duration: 100,
      delay: 0,
      smooth: "easeInOutQuart",
      offset: -60,
    });
  };

  return (
    <div className="main-wrapper">
      <MainHero scrollToElement={scrollToElement} />
      <Element className="overflow-hidden relative" name="targetElementName">
        <motion.div
          animate={{
            x: [0, -1035],
            transition: {
              x: {
                repeat: Infinity,
                repeatType: "loop",
                duration: 20,
                ease: "linear",
              },
            },
          }}
          style={{
            display: "flex",
            whiteSpace: "nowrap",
            width: "fit-content",
            backgroundColor: "#FFDB60",
            color: "#000",

            padding: "5px 0px",
          }}
          className="d-flex align-items-center m-0"
        >
          <h1 style={{ flexShrink: 0 }} className="m-0 fw-bold">
            JOIN PICKLEBALL-
          </h1>
          <h1 style={{ flexShrink: 0 }} className="m-0 fw-bold">
            JOIN PICKLEBALL-
          </h1>
          <h1 style={{ flexShrink: 0 }} className="m-0 fw-bold">
            JOIN PICKLEBALL-
          </h1>
          <h1 style={{ flexShrink: 0 }} className="m-0 fw-bold">
            JOIN PICKLEBALL-
          </h1>
          <h1 style={{ flexShrink: 0 }} className="m-0 fw-bold">
            JOIN PICKLEBALL-
          </h1>
          <h1 style={{ flexShrink: 0 }} className="m-0 fw-bold">
            JOIN PICKLEBALL-
          </h1>
          <h1 style={{ flexShrink: 0 }} className="m-0 fw-bold">
            JOIN PICKLEBALL-
          </h1>
          <h1 style={{ flexShrink: 0 }} className="m-0 fw-bold">
            JOIN PICKLEBALL-
          </h1>
          <h1 style={{ flexShrink: 0 }} className="m-0 fw-bold">
            JOIN PICKLEBALL-
          </h1>
          <h1 style={{ flexShrink: 0 }} className="m-0 fw-bold">
            JOIN PICKLEBALL-
          </h1>
          <h1 style={{ flexShrink: 0 }} className="m-0 fw-bold">
            JOIN PICKLEBALL-
          </h1>
          <h1 style={{ flexShrink: 0 }} className="m-0 fw-bold">
            JOIN PICKLEBALL-
          </h1>
          <h1 style={{ flexShrink: 0 }} className="m-0 fw-bold">
            JOIN PICKLEBALL-
          </h1>
          <h1 style={{ flexShrink: 0 }} className="m-0 fw-bold">
            JOIN PICKLEBALL-
          </h1>
          <h1 style={{ flexShrink: 0 }} className="m-0 fw-bold">
            JOIN PICKLEBALL-
          </h1>
          <h1 style={{ flexShrink: 0 }} className="m-0 fw-bold">
            JOIN PICKLEBALL-
          </h1>
          <h1 style={{ flexShrink: 0 }} className="m-0 fw-bold">
            JOIN PICKLEBALL-
          </h1>
          <h1 style={{ flexShrink: 0 }} className="m-0 fw-bold">
            JOIN PICKLEBALL-
          </h1>
        </motion.div>
      </Element>
      <Announcement />

      <section className="facility-section text-center">
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            y: { duration: 0.7 },
          }}
        >
          <div className="facility-container">
            <h2 className="section-title">FACILITIES</h2>
            <p className="section-description text-center">
              스포모티브에서 <span className="point-color">새로운 경험</span>을
              시작하세요.
            </p>
            <p className="facility-text text-center">
              피클볼 전용 코트를 중심으로,
              <br />
              요가와 런닝 트레이닝 시설, 카페 & 바, 프리미엄 샤워실과 사우나까지
            </p>

            <div className="facility-image">
              <CustomSlider content={facilitySliderContent} />
            </div>
            <h2 className="facility-text-title text-center">
              피클볼 전용 코트
            </h2>
            <p className="facility-text-content text-center">
              스포모티브의 최신 피클볼 코트는 초보자부터 전문가까지
              <br />
              국제 규격의 최고급 시설에서{" "}
              <span className="point-color">최적의 스포츠 경험</span>을
              제공합니다.
            </p>
            <a href="facilities" rel="noopener noreferrer">
              <button className="button-color">자세히 보기</button>
            </a>
          </div>
        </motion.div>
        <Container className="p-0">
          <Facilities />
        </Container>
        {/* </div> */}
      </section>
      <div className="program-section text-center">
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            y: { duration: 0.7 },
          }}
        >
          <h2 className="section-title">COMING SOON</h2>
          <p className="section-description text-center">
            다양한 피클볼 프로그램이{" "}
            <span className="point-color">곧 시작</span>
            됩니다.
          </p>
        </motion.div>
        <Container>
          <Program />
        </Container>
      </div>
      <div className="notice-section text-center">
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            y: { duration: 0.7 },
          }}
        >
          <h2 className="fw-bolds section-title">NEWS</h2>
          <h2 className="section-description">
            스포모티브의 <span className="point-color">소식</span>을
            전달해드립니다
          </h2>
        </motion.div>
        <Container>
          <Notice />
        </Container>
      </div>
      <div className="main-sponsored-section text-center">
        <MainSponsor />
      </div>
      <div className="sub-sponsored-section text-center">
        <SubSponsor />
      </div>
    </div>
  );
}

export default Main;
