import React, { useState } from "react";
import { motion } from "framer-motion";
import "../styles/BrandPage.css";
import SubHero from "../components/SubHero.js";
import BrandAboutUsSlider from "../components/BrandAboutUsSlider.js";

function Brand() {
  const [isLeftHovered, setIsLeftHovered] = useState(false);
  const [isRightHovered, setIsRightHovered] = useState(false);

  const handleLeftMouseEnter = () => {
    setIsLeftHovered(true);
  };

  const handleLeftMouseLeave = () => {
    setIsLeftHovered(false);
  };

  const handleRightMouseEnter = () => {
    setIsRightHovered(true);
  };

  const handleRightMouseLeave = () => {
    setIsRightHovered(false);
  };

  return (
    <div className="brand-wrapper">
      <SubHero title={"Brand"} text={"우리의 이야기"} />
      <div className="brand-backgroud">
        <section className="our-story-section">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              ease: "easeInOut",
              duration: 0.5,
              y: { duration: 0.7 },
            }}
          >
            <h4 className="our-story-topic">OUR STORY</h4>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              ease: "easeInOut",
              duration: 1,
              y: { duration: 1.5 },
              delay: 0.5,
            }}
          >
            <h5 className="our-story-title">“피클볼로 시작되는 여정”</h5>
            <p className="our-story-content">
              스포모티브는 피클볼로 시작된 작은 움직임이었습니다.
              <br />
              누구나 쉽게 참여하며 즐길 수 있는 운동 문화를 만들고, 세대를
              연결해 건강한 소통이 피어나는 공간을 꿈 꿉니다.
              <br />
              지금은 그저 첫 발걸음일 뿐이지만, 더 많은 사람들이 피클볼을 통해
              함께 연결되길 기대합니다.
            </p>
          </motion.div>
        </section>
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            y: { duration: 1 },
          }}
        >
          <div className="brand-arrow text-center">
            <img src="/icon/brand-arrow.svg" loading="lazy" alt="brand arrow" />
          </div>
        </motion.div>
        <section className="about-us-section">
          <div className="about-us-wrap">
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeInOut",
                duration: 0.5,
                y: { duration: 0.7 },
              }}
            >
              <h4 className="about-us-topic">ABOUT US</h4>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 100 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                ease: "easeInOut",
                duration: 1,
                y: { duration: 1.5 },
                delay: 0.5,
              }}
            >
              <h5 className="about-us-title">“피클볼 그 이상의 경험”</h5>
            </motion.div>
            <BrandAboutUsSlider />
          </div>
        </section>
        <section className="for-you-section">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              ease: "easeInOut",
              duration: 0.5,
              y: { duration: 0.7 },
            }}
          >
            <h5 className="for-you-title">“스포모티브가 당신을 위한 이유”</h5>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              ease: "easeInOut",
              duration: 1,
              y: { duration: 1.5 },
              delay: 0.5,
            }}
          >
            <h4 className="for-you-topic">I AM NEW TO</h4>
          </motion.div>
          <div class="for-you-content-container">
            <div class="for-you-content-wrap">
              <div class="diagonal-container">
                <div
                  class={`left-content ${
                    isRightHovered ? "right-hovered" : ""
                  }`}
                  onMouseEnter={handleLeftMouseEnter}
                  onMouseLeave={handleLeftMouseLeave}
                >
                  <div
                    className={`left-original-content ${
                      isRightHovered ? "slide-out" : ""
                    }`}
                  >
                    <div class="left-content-img-wrap">
                      <img src="/icon/for-you-left.svg" alt="for-you-left" />
                    </div>
                    <p
                      className="left-content-text"
                      style={{
                        opacity: isLeftHovered ? 0 : 1,
                        transition: "opacity 0.3s ease",
                        pointerEvents: isLeftHovered ? "none" : "auto",
                      }}
                    >
                      피클볼
                    </p>
                  </div>
                  <div
                    className={`left-hover-content ${
                      isRightHovered ? "slide-in" : ""
                    }`}
                  >
                    <div className="left-hover-content-wrap text-end">
                      <p className="left-hover-text-title">
                        스포모티브에서
                        <br />
                        새로운 피클볼 문화를 만나보세요!
                      </p>
                      <p className="left-hover-text-content">
                        피클볼을 사랑하지만 특별한 경험을 원한다면,
                        <br />
                        스포모티브에서 다양한 프로그램과
                        <br />
                        혜택을 누려보세요.
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={`right-content ${
                    isLeftHovered ? "left-hovered" : ""
                  }`}
                  onMouseEnter={handleRightMouseEnter}
                  onMouseLeave={handleRightMouseLeave}
                >
                  <div
                    className={`right-original-content ${
                      isLeftHovered ? "slide-out" : ""
                    }`}
                  >
                    <div className="right-content-img-wrap">
                      <img
                        src="/icon/for-you-right.svg"
                        loading="lazy"
                        alt="for-you-right"
                      />
                    </div>
                    <p
                      className="right-content-text"
                      style={{
                        opacity: isLeftHovered || isRightHovered ? 0 : 1,
                        transition: "opacity 0.3s ease",
                        pointerEvents:
                          isLeftHovered || isRightHovered ? "none" : "auto",
                      }}
                    >
                      스포모티브
                    </p>
                  </div>
                  <div
                    className={`right-hover-content ${
                      isLeftHovered ? "slide-in" : ""
                    }`}
                  >
                    <div className="right-hover-content-wrap text-start">
                      <p className="right-hover-text-title">
                        피클볼이 처음이라면,
                        <br />
                        걱정하지마세요!
                      </p>
                      <p className="right-hover-text-content">
                        초보자를 위한 기초 강습과 아카데미
                        <br />
                        그리고 시설 투어를 통해 쉽고 즐겁게 <br />
                        피클볼을 시작할 수 있도록 돕겠습니다.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="mission-vision-section">
        <div className="mission-vision-warp">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              ease: "easeInOut",
              duration: 0.5,
              y: { duration: 0.7 },
            }}
          >
            <h4 className="mission-vision-title">“Mission&Vision”</h4>
          </motion.div>
          <div className="mission-vision-content-wrap">
            <div className="mission-vision-content">
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.5,
                  y: { duration: 0.7 },
                  delay: 0.3,
                }}
              >
                <p>
                  <span className="mission-vision-content-white">
                    스포모티브
                  </span>
                  는{" "}
                  <span className="mission-vision-content-white">피클볼</span>과{" "}
                  <span className="mission-vision-content-white">사람</span>을
                  연결하며,
                  <br />
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.5,
                  y: { duration: 0.7 },
                  delay: 0.5,
                }}
              >
                <p>
                  <span className="mission-vision-content-white">즐거움</span>과{" "}
                  <span className="mission-vision-content-white">열정</span>을
                  통해
                  <br />
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.5,
                  y: { duration: 0.7 },
                  delay: 0.7,
                }}
              >
                <p>
                  모든 사람들에게{" "}
                  <span className="mission-vision-content-white">긍정적</span>
                  이고,
                  <br />
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                  ease: "easeInOut",
                  duration: 0.5,
                  y: { duration: 0.7 },
                  delay: 0.9,
                }}
              >
                <p>
                  <span className="mission-vision-content-white">
                    지속 가능한 문화를 혁신하고 확장
                  </span>
                  합니다.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
      <section className="promise-section">
        <div className="promise-warp">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              ease: "easeInOut",
              duration: 0.5,
              y: { duration: 0.7 },
            }}
          >
            <h4 className="promise-title">고객과의 약속</h4>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              ease: "easeInOut",
              duration: 1,
              y: { duration: 1.5 },
              delay: 0.5,
            }}
          >
            <p className="promise-content">
              초보자도 쉽게 적응할 수 있는 친절한 강습과 가이드를 제공하며
              <br />
              수준높은 시설과 쾌적한 환경을 통해
              <br />
              모두가 만족할 수 있는 경험을 선사하겠습니다.
              <br />
            </p>
          </motion.div>
        </div>
      </section>
    </div>
  );
}

export default Brand;
