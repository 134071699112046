import React, { useState, useRef } from "react";
import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImageMap } from "../assets/constants/facility-image-map.js";
import "../styles/CustomImageSlider.css";

const ImageDots = ({ content, currentSlide, onDotClick }) => {
  return (
    <div className="image-dots">
      {content.map((item, index) => (
        <div
          key={index}
          className={`image-dot ${currentSlide === index ? "active" : ""}`}
          onClick={() => onDotClick(index)}
        >
          <img
            src={ImageMap[item.image]}
            alt={`dot ${index + 1}`}
            loading="lazy"
          />
        </div>
      ))}
    </div>
  );
};

function CustomSlider({ content }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const handleDotClick = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
  };

  return (
    <Container className="custom-slider-container">
      <div className="custom-slider-wrap">
        <Slider {...settings} ref={sliderRef}>
          {content.map((item, index) => (
            <div key={index}>
              <img
                className="custom-slider-wrap-img"
                src={ImageMap[item.image]}
                alt={`slide ${index + 1}`}
                loading="lazy"
              />
            </div>
          ))}
        </Slider>
      </div>
      <ImageDots
        content={content}
        currentSlide={currentSlide}
        onDotClick={handleDotClick}
      />
    </Container>
  );
}

export default CustomSlider;
