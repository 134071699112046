import React from "react";
import { Image } from "react-bootstrap";
import { motion } from "framer-motion";
import "../styles/MainHero.css";

function MainHero({ scrollToElement }) {
  return (
    <section className="hero-section">
      <Image src="/hero.png" alt="Hero" className="hero-img" fluid />
      <div className="hero-logo">
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            y: { duration: 0.7 },
            delay: 0,
          }}
        >
          <div>
            <img
              src="/icon/hero-logo.svg"
              alt="SPOMOTIVE"
              className="text-flicker-in-glow"
            />
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            y: { duration: 1 },
            delay: 0.5,
          }}
        >
          <div className="hero-text">
            <p className="emerging-text">Emerging Sports</p>
            <p className="main-text">피클볼, 그 이상의 경험</p>
          </div>
          <button className="hero-more-button" onClick={scrollToElement}>
            <span>더 알아보기</span>
          </button>
        </motion.div>
      </div>
    </section>
  );
}

export default MainHero;
