import { Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ImageMap } from "../assets/constants/facility-image-map.js";
import "../styles/CustomSlider.css";

function CustomSlider({ content }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ul className="m-0 p-0">{dots}</ul>
      </div>
    ),
  };

  return (
    <Container className="custom-slider-container">
      <div className="custom-slider-wrap">
        <Slider {...settings}>
          {content.map((item, index) => (
            <div key={index}>
              <img
                className="custom-slider-wrap-img"
                src={ImageMap[item.image]}
                alt={`slide ${index + 1}`}
                loading="lazy"
              />
            </div>
          ))}
        </Slider>
      </div>
    </Container>
  );
}

export default CustomSlider;
