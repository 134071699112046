import React from "react";
import { Image } from "react-bootstrap";
import { motion } from "framer-motion";
import "../styles/SubHero.css";

function SubHero(props) {
  return (
    <div className="sub-hero-section">
      <Image src="/sub-hero.png" className="sub-hero-img" fluid />
      <div className="text-wrap">
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            y: { duration: 1 },
            delay: 0,
          }}
        >
          <div>
            <h2 className="sub-hero-main-text">{props.title}</h2>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            y: { duration: 1 },
            delay: 0.5,
          }}
        >
          <div>
            <p className="sub-hero-sub-text">{props.text}</p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}

export default SubHero;
