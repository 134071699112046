// 로고 사진
import Joola from "../images/logo/joola.png";
import Joola_purple from "../images/logo/joola-purple.png";
import ENGAGE from "../images/logo/partner-ENGAGE.png";
import NH from "../images/logo/partner-NH.png";
import PADDLETEK from "../images/logo/partner-PADDLETEK.png";
import SELKIRK from "../images/logo/partner-SELKIRK.png";
import TITANBALLMACHINE from "../images/logo/partner-TITAN-BALL-MACHINE.png";
import KOREABOARDGAME from "../images/logo/partner-KOREA-BOARD-GAME.png";
import SpoMotive from "../images/logo/spomotive.png";
import Insta from "../images/logo/insta.png";
import Naver from "../images/logo/naver.png";
import SPARKLE_MAIN from "../images/logo/sparkle-main.png";
import SPARKLE from "../images/logo/sparkle.png";

export const ImageMap = {
  spomotive: SpoMotive,
  insta: Insta,
  naver: Naver,
  joola: Joola,
  joola_purple: Joola_purple,
  ENGAGE: ENGAGE,
  NH: NH,
  PADDLETEK: PADDLETEK,
  SELKIRK: SELKIRK,
  "TITAN-BALL-MACHINE": TITANBALLMACHINE,
  "KOREA-BOARD-GAME": KOREABOARDGAME,
  SPARKLE: SPARKLE,
  SPARKLE_MAIN: SPARKLE_MAIN,
};
