import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Slider from "react-slick";
import { ImageMap } from "../assets/constants/brand-image-map.js";
import "../styles/BrandAboutUsSlider.css";

const BrandAboutUsSlider = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    // customPaging: function (i) {
    //   return <div className="custom-dot"></div>;
    // },
  };

  if (isMobile) {
    return (
      <div className="about-us-list-mobile-container">
        <div className="about-us-list-mobile-wrap">
          <Slider {...settings}>
            <div className="about-us-item-wrap">
              <img
                src={ImageMap["about-us-1"]}
                loading="lazy"
                alt="about us culture"
              />
              <div className="about-us-item-content">
                <div className="about-us-content-top-bar"></div>
                <div className="about-us-content-wrap">
                  <h6 className="about-us-content-title">"피클볼"</h6>
                  <hr className="about-us-content-line" />
                  <p className="about-us-content-text">
                    스포모티브는 피클볼이라는 혁신적인 스포츠를 통해 모두가 쉽게
                    참여할 수 있는 운동 문화를 만들겠다는 비전에서
                    시작되었습니다.
                  </p>
                </div>
              </div>
            </div>
            <div className="about-us-item-wrap">
              <img
                src={ImageMap["about-us-2"]}
                loading="lazy"
                alt="about us link"
              />
              <div className="about-us-item-content">
                <div className="about-us-content-top-bar"></div>
                <div className="about-us-content-wrap">
                  <h6 className="about-us-content-title">"운동 문화"</h6>
                  <hr className="about-us-content-line" />
                  <p className="about-us-content-text">
                    다양한 세대를 연결하고, 피클볼을 통해 사람들과 소통하며,
                    건강한 문화를 이끌 수 있는 공간을 목표로 합니다.
                  </p>
                </div>
              </div>
            </div>
            <div className="about-us-item-wrap">
              <img
                src={ImageMap["about-us-3"]}
                loading="lazy"
                alt="about us pickleball"
              />
              <div className="about-us-item-content">
                <div className="about-us-content-top-bar"></div>
                <div className="about-us-content-wrap">
                  <h6 className="about-us-content-title">"세대 연결"</h6>
                  <hr className="about-us-content-line" />
                  <p className="about-us-content-text">
                    우리가 걸어온 길은 아직 시작에 불과하며, 앞으로도 더 많은
                    사람들과 피클볼을 통해 연결되기를 기대합니다.
                  </p>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    );
  }

  return (
    <div className="about-us-list-container">
      <div className="about-us-list-wrap">
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            y: { duration: 1.5 },
            delay: 0.5,
          }}
        >
          <div className="about-us-item-wrap">
            <img
              src={ImageMap["about-us-1"]}
              loading="lazy"
              alt="about us culture"
            />
            <div className="about-us-item-content">
              <div className="about-us-content-top-bar"></div>
              <div className="about-us-content-wrap">
                <h6 className="about-us-content-title">"피클볼"</h6>
                <hr className="about-us-content-line" />
                <p className="about-us-content-text">
                  스포모티브는 피클볼이라는 혁신적인 스포츠를 통해 모두가 쉽게
                  참여할 수 있는 운동 문화를 만들겠다는 비전에서 시작되었습니다.
                </p>
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            y: { duration: 1.5 },
            delay: 0.7,
          }}
        >
          <div className="about-us-item-wrap">
            <img
              src={ImageMap["about-us-2"]}
              loading="lazy"
              alt="about us link"
            />
            <div className="about-us-item-content">
              <div className="about-us-content-top-bar"></div>
              <div className="about-us-content-wrap">
                <h6 className="about-us-content-title">"운동 문화"</h6>
                <hr className="about-us-content-line" />
                <p className="about-us-content-text">
                  다양한 세대를 연결하고, 피클볼을 통해 사람들과 소통하며,
                  건강한 문화를 이끌 수 있는 공간을 목표로 합니다.
                </p>
              </div>
            </div>
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            y: { duration: 1.5 },
            delay: 1,
          }}
        >
          <div className="about-us-item-wrap">
            <img
              src={ImageMap["about-us-3"]}
              loading="lazy"
              alt="about us pickleball"
            />
            <div className="about-us-item-content">
              <div className="about-us-content-top-bar"></div>
              <div className="about-us-content-wrap">
                <h6 className="about-us-content-title">"세대 연결"</h6>
                <hr className="about-us-content-line" />
                <p className="about-us-content-text">
                  우리가 걸어온 길은 아직 시작에 불과하며, 앞으로도 더 많은
                  사람들과 피클볼을 통해 연결되기를 기대합니다.
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default BrandAboutUsSlider;
