import React from "react";
import "../styles/Notice.css";
import { motion } from "framer-motion";

const NoticeWarp = (props) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{
        ease: "easeInOut",
        duration: 1,
        y: { duration: 0.7 },
      }}
    >
      <div className="notice-item-container">
        <a href="news">
          <div className="notice-warp">
            <div className="notice-item-warp">
              <div className="notice-title">
                <h3>{props.title}</h3>
              </div>
              <div className="notice-content">
                <p dangerouslySetInnerHTML={{ __html: props.description }}></p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </motion.div>
  );
};

function Notice() {
  const noticeData = [
    {
      title: "2024년 11월 중순 임시 오픈!",
      description: `스포모티브는 현재 세부 공사가 진행 중이지만,<br/>
      프리미엄 피클볼 코트 2면은 완벽히 준비되어 운영되고 있습니다.<br/>
      편안한 환경에서 최고의 시설을 경험하며 마음껏 플레이해  보세요.`,
    },
    {
      title: "12월 부터 피클볼 전문 레슨 프로그램 진행",
      description: `정기적인 무료 피클볼 스킬 클리닉도 진행해<br/>
      누구나 쉽게 참여하고 실력을 키울 수 있는 기회를 제공합니다.<br/>
      스포모티브에서 다양한 레벨에 맞춘 맞춤형 레슨으로 피클볼의 즐거움을 느껴보세요!`,
    },
    {
      title: "2024년 2월 정식 오픈 !",
      description: `<span style="color: #FBD85E;">정식 오픈 이후에는 샤워실, 요가 공간, 러닝 시설</span>  프리미엄 서비스로 더욱 쾌적하고<br/>
다채로운 스포츠 환경을 제공합니다. `,
    },
    {
      title: "피클볼 주니어 겨울방학 특강 오픈",
      description: `<span style="color: #FBD85E;">정식 오픈 이후에는 샤워실, 요가 공간, 러닝 시설</span>  프리미엄 서비스로 더욱 쾌적하고<br/>
  다채로운 스포츠 환경을 제공합니다. `,
    },
  ];

  return (
    <div>
      <div className="notice-container">
        {noticeData.map((notice, index) => (
          <NoticeWarp title={notice.title} description={notice.description} />
        ))}
      </div>
    </div>
  );
}

export default Notice;
