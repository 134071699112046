import React, { useEffect, useRef, useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import "../styles/Header.css";
import { ImageMap } from "../assets/constants/logo-image-map";

function Header({ location, navigate }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const navbarRef = useRef(null);
  const isMainPage = location && location.pathname === "/";

  // 페이지 이동 시 스크롤 최상단으로 이동
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]); // location이 변경될 때마다 실행

  const handleNavToggle = (expanded) => {
    setIsExpanded(expanded);
  };

  const handleNavLinkClick = () => {
    setIsExpanded(false);
    window.scrollTo(0, 0); // 네비게이션 링크 클릭 시에도 스크롤 최상단으로
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (location && location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else if (navigate) {
      navigate("/");
      window.scrollTo(0, 0);
    }
    setIsExpanded(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsVisible(window.scrollY > 0);
    };

    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    // 초기 스크롤 위치 확인
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMainPage]);

  return (
    <header>
      <Navbar
        ref={navbarRef}
        className={`navbar-custom ${
          isMainPage && !isVisible ? "hidden" : ""
        } fixed-top`}
        variant="dark"
        expand="lg"
        onToggle={handleNavToggle}
        expanded={isExpanded}
      >
        <Container className="navbar-container">
          <Navbar.Brand onClick={handleLogoClick}>
            <img src={ImageMap["spomotive"]} alt={"spomotive"} />
          </Navbar.Brand>
          <div className="navbar-wrap">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav>
              <Nav.Link href="/brand" onClick={handleNavLinkClick}>
                브랜드 소개
              </Nav.Link>
              <Nav.Link href="/facilities" onClick={handleNavLinkClick}>
                시설
              </Nav.Link>
              <Nav.Link href="/programs" onClick={handleNavLinkClick}>
                프로그램
              </Nav.Link>
              <Nav.Link
                target="_blank"
                href="https://smartstore.naver.com/playpickleball"
              >
                스토어
              </Nav.Link>
              <Nav.Link href="/news" onClick={handleNavLinkClick}>
                공지사항
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
