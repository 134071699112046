import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ImageMap } from "../assets/constants/facility-image-map.js";
import { motion } from "framer-motion";
import "../styles/Main.css";

const FacilityContent = (props) => {
  return (
    <div className="facility-sub-text">
      <h3 className="facility-sub-title">{props.title}</h3>
      <p
        className="facility-sub-content"
        dangerouslySetInnerHTML={{ __html: props.description }}
      ></p>
    </div>
  );
};

const FacilityImage = (props) => {
  return (
    <div className="facility-sub-image">
      <img loading="lazy" src={ImageMap[props.img]} alt={props.alt} />
    </div>
  );
};

const LeftFacility = (props) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{
        ease: "easeInOut",
        duration: 1,
        y: { duration: 0.5 },
      }}
    >
      <Container className="facility-list-container">
        <Row className="d-flex align-items-cente justify-content-center text-end">
          <Col md={6} className="facility-sub-text-left order-md-1 order-2">
            <FacilityContent
              title={props.title}
              description={props.description}
            />
          </Col>
          <Col
            md={6}
            className="facility-sub-image-wrap-left order-md-2 order-1"
          >
            <FacilityImage img={props.img} alt={props.alt} />
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
};

const RightFacility = (props) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{
        ease: "easeInOut",
        duration: 1,
        y: { duration: 0.7 },
      }}
    >
      <Container className="facility-list-container">
        <Row className="d-flex align-items-cente justify-content-center text-start">
          <Col
            md={6}
            className="facility-sub-image-wrap-right order-md-1 order-1"
          >
            <FacilityImage img={props.img} alt={props.alt} />
          </Col>
          <Col md={6} className="facility-sub-text-right order-md-2 order-2">
            <FacilityContent
              title={props.title}
              description={props.description}
            />
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
};

function Facilities() {
  const facilityData = [
    {
      title: "요가 및 러닝",
      description: `스포모티브의 요가 & 런닝 시설에서<br/>
            건강한 몸과 마음을 가꾸어보세요.<br/>
            집중할 수 있는 공간에서<br/>
            <span style="color: var(--primary-color)">차별화된 경험</span>을 제공해드립니다.`,
      image: "yoga-running",
      alt: "요가 및 러닝",
    },
    {
      title: "카페 & 바",
      description: `스포모티브의 카페 & 바에서<br/> 
            운동 후 피로를 풀고, 친구들과 함께<br/>
            즐거운 시간을 보내세요.<br/>
            다양한 음료와 안락한 분위기에서<br/>
            <span style="color: var(--primary-color)">편안한 휴식</span>을 제공합니다.`,
      image: "cafe-bar",
      alt: "카페 & 바",
    },
    {
      title: "샤워 시설",
      description: `스포모티브의 고급 샤워시설에서<br/> 
            운동 후 지친 몸을 풀고,<br/> 
            <span style="color: var(--primary-color)">완벽한 힐링</span>을 경험해보세요`,
      image: "shower",
      alt: "샤워 시설",
    },
    {
      title: "피클볼 장비",
      description: `스포모티브에서 제공하는 피클볼 장비 쇼핑 공간!<br/> 
            최신 장비부터 피클볼에 필요한 다양한 액세서리까지<br/>
            <span style="color: var(--primary-color)">온오프라인에서 편리하게</span> 쇼핑하세요.`,
      image: "proshop",
      alt: "피클볼 장비",
    },
  ];

  return (
    <div className="facilities-container">
      {facilityData.map((facility, index) =>
        index % 2 === 0 ? (
          <LeftFacility
            key={index}
            title={facility.title}
            description={facility.description}
            img={facility.image}
          />
        ) : (
          <RightFacility
            key={index}
            title={facility.title}
            description={facility.description}
            img={facility.image}
          />
        )
      )}
    </div>
  );
}

export default Facilities;
