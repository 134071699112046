import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Main from "./pages/Main.js";
import Header from "./components/Header.js";
import Brand from "./pages/Brand.js";
import Facilities from "./pages/Facilities.js";
import News from "./pages/News.js";
import Programs from "./pages/Programs.js";

function HeaderWrapper() {
  const location = useLocation();
  const navigate = useNavigate();

  return <Header location={location} navigate={navigate} />;
}

function AppContent() {
  return (
    <>
      <HeaderWrapper />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/brand" element={<Brand />} />
        <Route path="/facilities" element={<Facilities />} />
        <Route path="/news" element={<News />} />
        <Route path="/programs" element={<Programs />} />
      </Routes>
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppContent />
    </BrowserRouter>
  );
}

export default App;
