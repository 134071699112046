// SponsorBanner.js
import React from "react";
import "../styles/Sponsor.css";
import { ImageMap } from "../assets/constants/logo-image-map.js";
import { motion } from "framer-motion";
import styled from "styled-components";

const sponsors = [
  { id: 1, name: "ENGAGE", image: "ENGAGE" },
  // { id: 2, name: "NH", image: "NH" },
  { id: 2, name: "PADDLETEK", image: "PADDLETEK" },
  { id: 3, name: "SELKIRK", image: "SELKIRK" },
  { id: 4, name: "TITAN BALL MACHINE", image: "TITAN-BALL-MACHINE" },
  // { id: 6, name: "KOREA BOARD GAME", image: "KOREA-BOARD-GAME" },
  // { id: 5, name: "Sparkle", image: "SPARKLE" },
];

const Container = styled.div`
  overflow: hidden;
  position: relative;
`;

const MarqueeWrapper = styled(motion.div)`
  display: inline-flex;
  width: fit-content;
  padding: 13px 0px;
`;

const LogoWrap = styled.div`
  width: 100px;
  height: auto;
  display: flex;
  align-items: center;
  margin-right: 100px;

  @media (max-width: 768px) {
    margin-right: 30px;
  }
`;

const SponsorLogo = styled.img`
  width: 100%;
  height: 100%;
  align-items: center;
  object-fit: contain;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px;
`;

const SubSponsor = () => {
  const duplicatedSponsors = [
    ...sponsors,
    ...sponsors,
    ...sponsors,
    ...sponsors,
    ...sponsors,
    ...sponsors,
  ];

  return (
    <Container>
      <MarqueeWrapper
        animate={{
          x: [0, -1035],
          transition: {
            x: {
              repeat: Infinity,
              repeatType: "loop",
              duration: 40,
              ease: "linear",
            },
          },
        }}
      >
        {duplicatedSponsors.map((sponsor, index) => (
          <LogoContainer>
            <LogoWrap>
              <SponsorLogo
                loading="lazy"
                key={`sponsor-${index}`}
                src={ImageMap[sponsor.image]}
                alt={sponsor.name}
              />
            </LogoWrap>
          </LogoContainer>
        ))}
      </MarqueeWrapper>
    </Container>
  );
};

export default SubSponsor;
