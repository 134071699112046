import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "../styles/ProgramSlider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextArrow = ({ onClick }) => (
  <button className="program-arrow-button" onClick={onClick}>
    <svg
      width="13"
      height="18"
      viewBox="0 0 12 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.67969 2.3125L9.46181 8.96509L1.67969 16.3203"
        stroke="#A6A6A6"
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  </button>
);

const PrevArrow = ({ onClick }) => (
  <button className="program-arrow-button" onClick={onClick}>
    <svg
      width="13"
      height="18"
      viewBox="0 0 13 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.7031 2.3125L2.92101 8.96509L10.7031 16.3203"
        stroke="#A6A6A6"
        stroke-width="3"
        stroke-linecap="round"
      />
    </svg>
  </button>
);

const Progress = ({ total, current, onDotClick, type }) => {
  return (
    <div
      className={`${
        type === "right-slider"
          ? "program-right-progress-bar"
          : "program-left-progress-bar"
      }`}
    >
      {[...Array(total)].map((_, index) => (
        <div
          key={index}
          className={`program-progress-dot ${
            current === index ? "active" : ""
          }`}
          onClick={() => onDotClick(index)}
        />
      ))}
    </div>
  );
};

const ProgramSlider = ({ title, description, images, type }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
    }

    // 초기 화면 크기 체크
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();

    // 리사이즈 이벤트 리스너 추가
    window.addEventListener("resize", checkIsMobile);

    // 클린업
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);
  const handleDotClick = (index) => {
    sliderRef.current.slickGoTo(index);
  };
  let settings;

  if (type === "right-slider") {
    settings = {
      dots: false,
      infinite: true,
      speed: 500,
      cssEase: "cubic-bezier(0.770, 0.000, 0.175, 1.000)",
      slidesToShow: 2.3,
      slidesToScroll: 1,
      arrows: false,
      centerMode: false,
      centerPadding: "0px",
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      rtl: false,
      direction: "ltr",
      beforeChange: (current, next) => {
        setCurrentSlide(next);
      },
    };
  } else {
    settings = {
      dots: false,
      infinite: true,
      speed: 500,
      cssEase: "cubic-bezier(0.770, 0.000, 0.175, 1.000)",
      slidesToShow: 2.3,
      slidesToScroll: 1,
      arrows: false,
      centerMode: false,
      centerPadding: "0px",
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      rtl: true,
      direction: "rtl",
      beforeChange: (current, next) => {
        setCurrentSlide(next);
      },
    };
  }

  if (isMobile) {
    settings = {
      dots: false,
      infinite: true,
      speed: 500,
      cssEase: "cubic-bezier(0.770, 0.000, 0.175, 1.000)",
      slidesToShow: 2.3,
      slidesToScroll: 1,
      arrows: false,
      centerMode: false,
      centerPadding: "0px",
      autoplay: true,
      autoplaySpeed: 3000,
      pauseOnHover: true,
      rtl: false,
      direction: "ltr",
      beforeChange: (current, next) => {
        setCurrentSlide(next);
      },
    };

    return (
      <div className="program-slider-container">
        <div className="program-slider-content-wrap">
          <div className="program-slider-bar"></div>
          <h3
            className="program-slider-title"
            dangerouslySetInnerHTML={{ __html: title }}
          ></h3>
          <p
            className="program-slider-description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></p>
        </div>
        <div className="program-slider-wrapper">
          <Slider
            className="program-styled-slider"
            ref={sliderRef}
            {...settings}
          >
            {images.map((image, index) => (
              <img
                loading="lazy"
                className="program-right-slide-image"
                key={index}
                src={image}
                alt={`slide-${index}`}
              />
            ))}
          </Slider>
        </div>
        <div className="program-slider-progress-navigation">
          <Progress
            total={3}
            current={currentSlide}
            onDotClick={handleDotClick}
            type="right-slider"
          />
          <div className="program-slider-arrows-container">
            <PrevArrow onClick={() => sliderRef.current.slickPrev()} />
            <NextArrow onClick={() => sliderRef.current.slickNext()} />
          </div>
        </div>
      </div>
    );
  }

  return type === "right-slider" ? (
    <div className="program-slider-container">
      <div className="program-slider-content-wrap">
        <div className="program-slider-bar"></div>
        <h3
          className="program-slider-title"
          dangerouslySetInnerHTML={{ __html: title }}
        ></h3>
        <p
          className="program-slider-description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
        <div className="program-slider-progress-navigation">
          <Progress
            total={3}
            current={currentSlide}
            onDotClick={handleDotClick}
            type={`right-slider`}
          />
          <div className="program-slider-arrows-container">
            <PrevArrow onClick={() => sliderRef.current.slickPrev()} />
            <NextArrow onClick={() => sliderRef.current.slickNext()} />
          </div>
        </div>
      </div>
      <div className="program-slider-wrapper">
        <Slider className="program-styled-slider" ref={sliderRef} {...settings}>
          {images.map((image, index) => (
            <img
              loading="lazy"
              className="program-right-slide-image"
              key={index}
              src={image}
              alt={`slide-${index}`}
            />
          ))}
        </Slider>
      </div>
    </div>
  ) : (
    <div className="program-slider-container">
      <div className="program-slider-wrapper">
        <Slider className="program-styled-slider" ref={sliderRef} {...settings}>
          {images.map((image, index) => (
            <img
              loading="lazy"
              className="program-left-slide-image"
              key={index}
              src={image}
              alt={`slide-${index}`}
            />
          ))}
        </Slider>
      </div>
      <div className="program-slider-content-wrap">
        <div className="program-slider-bar"></div>
        <h3 className="program-slider-title">{title}</h3>
        <p
          className="program-slider-description"
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
        <div className="program-slider-progress-navigation">
          <div className="program-slider-arrows-container">
            <PrevArrow onClick={() => sliderRef.current.slickPrev()} />
            <NextArrow onClick={() => sliderRef.current.slickNext()} />
          </div>
          <Progress
            total={3}
            current={currentSlide}
            onDotClick={handleDotClick}
            type={`left-slider`}
          />
        </div>
      </div>
    </div>
  );
};

export default ProgramSlider;
