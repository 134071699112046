// 메인 시설 슬라이드 사진
import MainFacility1 from "../images/facility/main_facilities_1.png";
import MainFacility2 from "../images/facility/main_facilities_2.png";
import MainFacility3 from "../images/facility/main_facilities_3.png";
import MainFacility4 from "../images/facility/main_facilities_4.png";

// 시설
import CafeBar from "../images/facility/cafe-bar-coming-soon.png";
import Shower from "../images/facility/shower-coming-soon.png";
import YogaRunning from "../images/facility/yoga-running-coming-soon.png";
import ProShop from "../images/facility/pickleball-proshop.png";

// 시설 탭 슬라이드 사진
import Facility1 from "../images/facility/facilities_1.png";
import Facility2 from "../images/facility/facilities_2.png";
import Facility3 from "../images/facility/facilities_3.png";
import Facility4 from "../images/facility/facilities_4.png";
import Facility5 from "../images/facility/facilities_5.png";
import Facility6 from "../images/facility/facilities_6.png";

// 시설 장점 사진
import FacilityPros1 from "../images/facility/facilities_pros_1.png";
import FacilityPros2 from "../images/facility/facilities_pros_2.png";
import FacilityPros3 from "../images/facility/facilities_pros_3.png";
import FacilityPros4 from "../images/facility/facilities_pros_4.png";

export const ImageMap = {
  "cafe-bar": CafeBar,
  shower: Shower,
  "yoga-running": YogaRunning,
  proshop: ProShop,

  facility_1: Facility1,
  facility_2: Facility2,
  facility_3: Facility3,
  facility_4: Facility4,
  facility_5: Facility5,
  facility_6: Facility6,

  facility_pros_1: FacilityPros1,
  facility_pros_2: FacilityPros2,
  facility_pros_3: FacilityPros3,
  facility_pros_4: FacilityPros4,

  main_facilities_1: MainFacility1,
  main_facilities_2: MainFacility2,
  main_facilities_3: MainFacility3,
  main_facilities_4: MainFacility4,
};
