import React from "react";
import { ImageMap } from "../assets/constants/program-image-map";
import { motion } from "framer-motion";
import "../styles/Program.css";

function Program() {
  const programData = [
    {
      img: ImageMap["program-main"],
      title: "피클볼 아카데미",
      description: `초급부터 상급까지 결합된 그룹 레슨과 1:1 코칭을 통해 기본기 고급부터 기술까지`,
    },
    {
      img: ImageMap["program-main"],
      title: "오픈플레이 및 주말 리그전",
      description: `모든 플레이어들이 참여할 수 있는 오픈 커뮤니티 플레이와 정기적인 주말 리그전`,
    },
    {
      img: ImageMap["program-main"],
      title: "DUPR",
      description: `DUPR 점수 평가와 기술 발전을 위한 매치 플레이 및 이벤트 대관 가능.`,
    },
    {
      img: ImageMap["program-main"],
      title: "무료 원포인트 클리닉 BY 스파클",
      description: `스파클 지원으로 초보부터 기존 플레이어까지 참여 가능한 무료 클리닉 진행.`,
    },
    {
      img: ImageMap["program-main"],
      title: "러닝 아카데미",
      description: `최상위 트레드밀과 실내 트랙, GYM에서 쾌적한 러닝 환경 제공.`,
    },
  ];
  return (
    <div className="program-wrap">
      {programData.map((program, index) => (
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            ease: "easeInOut",
            duration: 1,
            y: { duration: 0.7 },
          }}
        >
          <div key={index} className="program-container">
            <div className={`program-image ${index !== 0 ? "blur-image" : ""}`}>
              <img loading="lazy" src={program.img} alt={program.title} />
              {index !== 0 && (
                <div className="image-overlay">
                  <span className="overlay-text">COMING SOON</span>
                </div>
              )}
            </div>
            <div className="program-content">
              <h3 className="program-title">{program.title}</h3>
              <p className="program-description">{program.description}</p>
              <a href="programs" className="program-link">
                프로그램 자세히 보기 >
              </a>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
}

export default Program;
