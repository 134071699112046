import { motion } from "framer-motion";
import styled from "styled-components";

const AnnouncementWrap = styled.div`
  text-align: center;
  background-color: #fff;
  padding: 40px 0px;
  color: #000;
`;

const Title = styled.h2`
  margin-bottom: 25px;
  font-size: var(--h5-size);
  font-weight: bold;
  color: var(--primary-color);
`;

const SubTitle = styled.p`
  font-size: var(--h2-size);
  font-weight: bold;
  margin-bottom: 11px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

const Description = styled.p`
  margin-bottom: 28px;
  font-size: var(--h6-size);
  font-weight: 500;
  color: #a6a6a6;
  @media (max-width: 576px) {
    font-size: 18px;
  }
`;

const Button = styled.button`
  transition: all 0.3s;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  padding: 10px 40px;
  background-color: var(--primary-color);
  border-radius: 5px;
  color: #000;
  font-weight: 700;
  border: 0;
  z-index: 1;
  margin-top: 20px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

function Announcement() {
  return (
    <AnnouncementWrap>
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{
          ease: "easeInOut",
          duration: 1,
          x: { duration: 0.7 },
          delay: 0,
        }}
      >
        <Title>GRAND OPEN</Title>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{
          ease: "easeInOut",
          duration: 1,
          x: { duration: 0.7 },
          delay: 0,
        }}
      >
        <SubTitle>2025년 2월</SubTitle>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{
          ease: "easeInOut",
          duration: 1,
          x: { duration: 0.7 },
          delay: 0,
        }}
      >
        <Description>
          정식 오픈 전, 스포모티브만의
          <br />
          프리미엄 피클볼 코드를 지금 바로 경험해보세요.
        </Description>
      </motion.div>
      <motion.div
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{
          ease: "easeInOut",
          duration: 1,
          x: { duration: 0.7 },
          delay: 0,
        }}
      >
        <a
          href="https://map.naver.com/p/entry/place/1082743699?c=15.00,0,0,0,dh&placePath=/ticket"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            className="custom-button-color"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.7 }}
          >
            예약하러 가기
          </Button>
        </a>
      </motion.div>
    </AnnouncementWrap>
  );
}

export default Announcement;
