import ProgramMain from "../images/program/program-main.png";

import Academy1 from "../images/program/academy_1.png";
import Academy2 from "../images/program/academy_2.png";
import Academy3 from "../images/program/academy_3.png";

import OpenPlayLeague1 from "../images/program/openplay-league_1.png";
import OpenPlayLeague2 from "../images/program/openplay-league_2.png";
import OpenPlayLeague3 from "../images/program/openplay-league_3.png";

import Clinic1 from "../images/program/clinic_1.png";
import Clinic2 from "../images/program/clinic_2.png";
import Clinic3 from "../images/program/clinic_3.png";

import Anthor1 from "../images/program/anthor-program_1.png";
import Anthor2 from "../images/program/anthor-program_2.png";
import Anthor3 from "../images/program/anthor-program_3.png";

export const ImageMap = {
    "program-main": ProgramMain,

    "academy_1": Academy1,
    "academy_2": Academy2,
    "academy_3": Academy3,

    "openplay-league_1": OpenPlayLeague1,
    "openplay-league_2": OpenPlayLeague2,
    "openplay-league_3": OpenPlayLeague3,

    "clinic_1": Clinic1,
    "clinic_2": Clinic2,
    "clinic_3": Clinic3,

    "anthor_1": Anthor1,
    "anthor_2": Anthor2,
    "anthor_3": Anthor3,
};