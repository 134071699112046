import React from "react";
import { Container } from "react-bootstrap";
import SubHero from "../components/SubHero.js";
import "../styles/ProgramPage.css";
import ProgramSlider from "../components/ProgramSlider.js";
import { ImageMap } from "../assets/constants/program-image-map";
import AnthorProgramSlider from "../components/AnthorProgramSlider.js";
import { motion } from "framer-motion";

function Programs() {
  return (
    <div className="program-wrapper">
      <SubHero
        title={"Programs"}
        text={"최고의 코치진과 미국 최상위 프로그램으로 완성된 최고의 퍼포먼스"}
      />
      <section className="program-list-container">
        <Container>
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              ease: "easeInOut",
              duration: 1,
              y: { duration: 0.7 },
            }}
          >
            <ProgramSlider
              title={"“피클볼 아카데미”"}
              description={`초보자부터 중급, 상급까지<br />
                            다양한 실력 수준에 맞춘 그룹 레슨과<br />
                            1:1 전문성 피드백이, 규칙, 스코어링, 포지셔닝 등<br />
                            기초기부터 고급 기술까지 체계적으로<br />
                            학습할 수 있는 기회를 제공합니다.`}
              images={[
                ImageMap["academy_1"],
                ImageMap["academy_2"],
                ImageMap["academy_3"],
              ]}
              type="right-slider"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              ease: "easeInOut",
              duration: 1,
              y: { duration: 0.7 },
            }}
          >
            <ProgramSlider
              title={"“오픈플레이와 주말 리그”"}
              description={`모든 실력 수준의 플레이어들이 참여할 수 있는<br />
                            오픈 커뮤니티 플레이와 정기적인 주말 리그전을 통해,<br />
                            즐거운 경기와 함께 새로운 사람들과 소통하며<br />
                            네트워크를 형성하고 실력을 발전시킬 수 있습니다.`}
              images={[
                ImageMap["openplay-league_1"],
                ImageMap["openplay-league_2"],
                ImageMap["openplay-league_3"],
              ]}
              type="left-slider"
            />
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              ease: "easeInOut",
              duration: 1,
              y: { duration: 0.7 },
            }}
          >
            <ProgramSlider
              title={"“무료 원포인트 클리닉<br/>BY 스파클”"}
              description={` 스포모티브에서는 주식회사 스파클의 지원으로<br />
                            피클볼 저변 확대를 위한 정기적인 무료 원포인트<br />
                            클리닉을 진행합니다. 초보자에게는 기초 기술을,<br />
                            기존 플레이어에게는 실력 향상의 기회를 제공하는<br />
                            유익한 프로그램으로, 모든 연령과 실력 수준에 맞춰<br />
                            누구나 부담 없이 참여할 수 있습니다.`}
              images={[
                ImageMap["clinic_1"],
                ImageMap["clinic_2"],
                ImageMap["clinic_3"],
              ]}
              type="right-slider"
            />
          </motion.div>
        </Container>
      </section>
      <section className="another-program-container">
        <AnthorProgramSlider
          contents={[
            {
              image: ImageMap["anthor_1"],
              description: `자신의 DUPR 점수를 평가하고 개인 기술을 발전시킬 수 있는<br/>
                                매치 플레이 프로그램을 제공하며, 코트 및 전체 시설 대관을 통해<br/>
                                소규모부터 대규모 이벤트까지 유연하게 운영할 수 있는 환경을 지원합니다.`,
              title: "FIND MY DUPR",
            },
            {
              image: ImageMap["anthor_2"],
              description: `DRAX 최상위 트레드밀 10대와 실내 트랙, 그리고 GYM을 갖춘 러닝 아카데미는<br/>
                                국내 최고의 실내 러닝 클래스를 제공합니다. 코트에서도 진행되는 다양한 프로그램을 통해<br/>
                                실내에서도 쾌적하고 효율적인 러닝을 경험할 수 있습니다.<br/>
                                최고의 환경에서 러닝의 즐거움과 운동의 효과를 동시에 누려보세요.`,
              title: "러닝 아카데미",
            },
            {
              image: ImageMap["anthor_3"],
              description: `그 외에도 스포모티브에서는 오직 이곳에서만 경험할 수 있는<br/>
                                특별한 클래스들이 다양하게 준비되고 있습니다.<br/>
                                기존에 접하지 못했던 차별화된 프로그램과 활동을 통해<br/>
                                새로운 운동의 즐거움과 특별한 경험을 선사할 예정입니다.`,
              title: "다양한 이벤트",
            },
          ]}
        />
        <div className="anthor-program-footer-wrap">
          <p className="anthor-program-footer-text">
            그 외 스포모티브에서 경험할 수 있는 다양한 클래스 준비중{" "}
          </p>
        </div>
      </section>
    </div>
  );
}

export default Programs;
