import React, { useState } from 'react';
import '../styles/Footer.css'
import { ImageMap } from '../assets/constants/logo-image-map';
import '../styles/Modal.css';

function Footer() {
    const readyClick = () => {
        alert("준비중입니다.");
    };
    const [modalState, setModalState] = useState({
        terms: false,
        privacy: false
    });

    const openModal = (type) => {
        setModalState(prev => ({
            ...prev,
            [type]: true
        }));
    };

    const closeModal = (type) => {
        setModalState(prev => ({
            ...prev,
            [type]: false
        }));
    };

    return (
        <footer className='footer-section'>
            <div className='footer-content-wrap'>
                <div className='footer-logo'>
                    <img src={ImageMap['spomotive']} alt={'spomotive'} />
                    <p className='footer-title-color'>스포모티브</p>
                </div>
                <div className='footer-sns-link'>
                    <div>

                    </div>
                    <a href="https://map.naver.com/p/entry/place/1082743699?c=15.00,0,0,0,dh&placePath=/home" target="_blank" rel="noopener noreferrer">
                        <div className="logo-circle">
                            <div className="logo-inner">
                                <img
                                    src={ImageMap['naver']}
                                    alt="Naver"
                                    className="logo-image"
                                />
                            </div>
                        </div>
                    </a>
                    <a onClick={readyClick} target="_blank" rel="noopener noreferrer">
                        <div className="logo-circle">
                            <div className="logo-inner">
                                <img
                                    src={ImageMap['insta']}
                                    alt="Insta"
                                    className="logo-image"
                                />
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <hr className='footer-title-color' />
            <div className="d-flex flex-column flex-lg-row justify-content-between">
                <div className="col-lg-6">
                    <div className="d-flex align-items-center">
                        <div>
                            <p className="footer-title-color">
                                <span
                                    onClick={() => openModal('terms')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    이용 약관
                                </span>
                                {' | '}
                                <span
                                    onClick={() => openModal('privacy')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    개인정보 처리 방침
                                </span>
                            </p>
                            <p className='footer-content-color'>사업자등록번호: 514-88-02941 | 대표자: 송지훈</p>
                            <p className='footer-content-color'>전화: 0507-1325-4349 | 팩스: 02-6455-4349</p>
                            <p className='footer-content-color'>주소: 서울 서초구 고무래로 18-4 지하 2층</p>
                        </div>
                    </div>
                </div>
                <div className='company-sitemap col-lg-6'>
                    <div className="d-flex flex-row flex-wrap">
                        <div className='col-6 col-md-3'>
                            <a href='/brand' className='footer-title-color text-center'>브랜드 소개</a>
                            <ul className="list-unstyled footer-content-color text-center">
                                {/* <li>상세 페이지</li>
                                <li>상세 페이지</li>
                                <li>상세 페이지</li> */}
                            </ul>
                        </div>
                        <div className='col-6 col-md-3'>
                            <a href='/facilities' className='footer-title-color text-center'>시설 안내</a>
                            <ul className="list-unstyled footer-content-color text-center">
                                {/* <li>상세 페이지</li>
                                <li>상세 페이지</li>
                                <li>상세 페이지</li> */}
                            </ul>
                        </div>
                        <div className='col-6 col-md-3'>
                            <a href='/programs' className='footer-title-color text-center'>프로그램 소개</a>
                            <ul className="list-unstyled footer-content-color text-center">
                                {/* <li>상세 페이지</li>
                                <li>상세 페이지</li>
                                <li>상세 페이지</li> */}
                            </ul>
                        </div>
                        <div className='col-6 col-md-3'>
                            <a href='/news' className='footer-title-color text-center'>공지사항</a>
                        </div>
                    </div>
                </div>
            </div>
            {modalState.terms && (
                <div className="modal-overlay" onClick={() => closeModal('terms')}>
                    <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <div className="modal-header">
                            <h2>이용 약관</h2>
                            <button onClick={() => closeModal('terms')}>&times;</button>
                        </div>
                        <div className="modal-body">
                            제1조 (목적)<br />
                            본 이용약관은 SpoMotive 공식 웹사이트(이하 "사이트")에서 제공하는 피클볼 시설 이용과 관련된 제반 사항을 규정하는 것을 목적으로 합니다.<br />
                            <br />
                            제2조 (용어의 정의)<br />
                            본 약관에서 사용되는 주요 용어의 정의는 다음과 같습니다.<br />
                            <br />
                            이용자: 사이트를 방문하여 서비스를 이용하는 자를 말합니다.<br />
                            이용계약: 이용자가 사이트를 통해 피클볼 시설을 이용하고, 관련 서비스를 이용하는 계약을 말합니다.<br />
                            예약: 사이트를 통해 피클볼 시설을 예약하는 행위로, 시설 이용을 위한 시간과 장소를 선택하는 것을 말합니다.<br />
                            운영자: 피클볼 시설의 서비스를 제공하고 운영하는 자를 말합니다.<br />
                            제3조 (약관 외 준칙)<br />
                            운영자는 필요한 경우 별도로 운영정책을 공지할 수 있으며, 본 약관과 운영정책이 중첩될 경우 운영정책이 우선 적용됩니다.<br />
                            <br />
                            제4조 (이용계약 체결)<br />
                            <br />
                            이용자는 본 약관에 동의하고, 피클볼 시설을 이용하기 위한 예약을 통해 이용계약을 체결합니다.<br />
                            예약을 통해 시설을 이용하려는 이용자는 예약 시 본 약관을 읽고 "동의합니다"를 선택하는 것으로 본 약관에 대한 동의를 표시합니다.<br />
                            제5조 (서비스 이용 신청)<br />
                            <br />
                            이용자는 사이트에서 요청하는 예약 정보를 제공해야 하며, 예약은 사이트 내 제공된 절차를 통해 이루어집니다.<br />
                            타인의 정보를 도용하거나 허위 정보를 등록하는 경우, 예약이 취소되거나 이용 권리가 제한될 수 있습니다.<br />
                            <br />
                            제6조 (개인정보처리방침)<br />
                            사이트는 이용자가 제공한 개인정보를 처리하며, 이에 대한 사항은 개인정보처리방침을 따릅니다. 운영자는 관계 법령에 따라 개인정보 보호에 필요한 조치를 취합니다.<br />
                            <br />
                            제7조 (운영자의 의무)<br />
                            운영자는 이용회원의 의견이나 불만을 정당하다고 인정할 경우 신속하게 처리하며, 처리할 수 없는 경우 사후에 통지합니다.<br />
                            운영자는 서비스 제공을 위한 설비의 장애 발생 시 이를 수리하거나 복구하도록 최선을 다합니다.<br />
                            <br />
                            제8조 (이용자의 의무)<br />
                            이용자는 약관을 준수하고 사이트에 방해가 되는 행위를 해서는 안됩니다.<br />
                            이용자는 예약된 시간에 맞춰 시설을 이용해야 하며, 시설 이용 중 발생한 손상이나 파손에 대해서 책임을 질 수 있습니다.<br />
                            <br />
                            제9조 (서비스 이용시간)<br />
                            서비스는 연중무휴 24시간 제공되며, 시스템 정기점검 및 기술적 문제로 서비스가 중단될 수 있습니다.<br />
                            <br />
                            제10조 (서비스 이용 해지)<br />
                            이용자는 예약을 취소할 수 있으며, 취소 정책에 따라 일부 환불이 적용될 수 있습니다.<br />
                            <br />
                            제11조 (서비스 이용 제한)<br />
                            이용자는 다음 행위를 하여서는 안되며, 위반 시 서비스 이용이 제한되거나 계약이 해지될 수 있습니다:<br />
                            <br />
                            허위 정보 등록<br />
                            타인의 정보 도용<br />
                            지적재산권 침해 등<br /><br />
                            제12조 (게시물의 관리)<br />
                            사이트는 게시물을 모니터링하며, 법적 규정에 위배되는 게시물을 삭제할 수 있습니다. 게시물의 저작권은 게시자에게 있으며, 운영자는 게시물을 상업적으로 이용할 수 없습니다.<br />
                            <br />
                            제13조 (손해배상)<br />
                            사이트의 손해배상 책임은 천재지변 등 불가항력적 사유가 있을 경우 면책됩니다.<br />
                            <br />
                            제14조 (면책)<br />
                            사이트는 서비스 이용 중 발생하는 손해에 대해 책임지지 않으며, 시스템 장애나 해킹 등으로 인한 손해도 책임지지 않습니다.<br />
                        </div>
                    </div>
                </div>
            )}
            {modalState.privacy && (
                <div className="modal-overlay" onClick={() => closeModal('privacy')}>
                    <div className="modal-content" onClick={e => e.stopPropagation()}>
                        <div className="modal-header">
                            <h2>개인정보 처리 방침</h2>
                            <button onClick={() => closeModal('privacy')}>&times;</button>
                        </div>
                        <div className="modal-body">
                            제1조 (개인정보 수집에 대한 동의)<br />
                            이용자는 사이트에서 제공하는 서비스를 이용하기 위해 개인정보 수집 및 이용에 동의합니다. "동의합니다" 버튼을 클릭함으로써 개인정보 수집 및 이용에 동의한 것으로 간주됩니다.<br />
                            <br />
                            제2조 (개인정보의 수집 항목 및 이용 목적)<br />
                            <br />
                            수집 항목:<br />
                            필수 항목: 이름, 연락처, 예약 정보<br />
                            선택 항목: 성별, 생년월일<br />
                            이용 목적: 서비스 제공, 예약 확인, 공지사항 전달<br />
                            보유 기간: 예약 완료 후 5년간 보관 (법령에 의한 보존 기간은 별도로 명시)<br />
                            제3조 (쿠키에 의한 개인정보 수집)<br />
                            사이트는 쿠키를 사용하여 고객의 관심 분야에 맞는 정보를 제공하고, 사이트 이용에 대한 분석을 합니다. 고객은 웹 브라우저 설정을 통해 쿠키를 거부할 수 있습니다.<br />
                            <br />
                            제4조 (개인정보의 보유 및 이용 기간 및 파기)<br />
                            고객의 개인정보는 목적 달성 후 즉시 파기됩니다. 관련 법령에 따라 일정 기간 보관이 필요한 정보는 별도로 저장됩니다.<br />
                            <br />
                            제5조 (제3자 제공)<br />
                            사이트는 고객의 개인정보를 제3자에게 제공하지 않으며, 법적 요구가 있을 경우 제공할 수 있습니다.<br />
                            <br />
                            제6조 (개인정보 취급 위탁)<br />
                            사이트는 일부 개인정보 취급 업무를 외부 전문업체에 위탁하여 운영합니다.<br />
                            <br />
                            제7조 (개인정보 열람 및 정정)<br />
                            고객은 언제든지 개인정보를 열람하거나 수정할 수 있으며, 정정 요청 시 즉시 조치합니다.<br />
                            <br />
                            제8조 (개인정보의 동의철회)<br />
                            이용자는 언제든지 개인정보 수집, 이용, 제공에 대한 동의를 철회할 수 있으며, 서비스 이용을 중지할 수 있습니다.<br />
                            <br />
                            제9조 (개인정보의 안전성 확보 조치)<br />
                            사이트는 개인정보를 암호화하여 저장하고, 해킹 및 바이러스 등으로부터 보호하기 위한 보안 조치를 취하고 있습니다.<br />
                            <br />
                            제10조 (14세 미만 아동의 개인정보 보호)<br />
                            사이트는 만 14세 미만 아동의 개인정보를 수집하지 않으며, 법정 대리인의 동의를 받지 않습니다.<br />
                            <br />
                            제11조 (개인정보 보호책임자)<br />
                            사이트의 개인정보 처리 책임자는 송지훈 대표이며, 개인정보 관련 문의는 이메일 또는 전화로 접수받습니다.<br />
                            <br />
                            제12조 (개인정보 처리방침 변경)<br />
                            이 개인정보처리방침은 시행일로부터 적용되며, 변경 사항은 사전 공지됩니다.<br />
                        </div>
                    </div>
                </div>
            )}
        </footer>
    );
}

export default Footer;